import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Menu from "./Menu"
import lottie from 'lottie-web'
import animationData from '../assets/HeaderAnimated.json'


const Nav = ({children}) => (
  <nav className="fixed top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 navbar lg:py-3 bg-purpleheart lg:bg-transparent">{children}</nav>
)

const NavContainer = ({children}) => (
  <div className="flex flex-wrap items-center justify-between px-4 mx-auto my-container">{children}</div>
)

const Header = () => {

  const refAnimationContainer = useRef()
  const lottieInstance = useRef()

  useEffect(() => {
    lottieInstance.current = lottie.loadAnimation({
      container: refAnimationContainer.current,
      animationData: animationData,
      loop: false,
      autoplay: false
    })

    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      document.addEventListener('scroll', function() {
        if (window.pageYOffset < 200) {
          lottieInstance.current.setDirection(-1)
        } else {
          lottieInstance.current.setDirection(1)
        }
        lottieInstance.current.play()
      })
    }
  }, [])

  return (
    <header className="relative" style={{ zIndex:'999999999' }}>
      <div ref={ refAnimationContainer } className="fixed top-0 z-40 hidden lg:block" aria-label="animated background"></div>
      <Nav>
        <NavContainer>
          <Menu />
        </NavContainer>
      </Nav>    
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
