import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { InView } from 'react-intersection-observer'
import backgroundData from '../assets/Footer.json'
import fbData from '../assets/facebook-icon.json'
import ytData from '../assets/Youtube-icon.json'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'
import FooterSocialLink from './FooterSocialLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import IconFb from '../assets/facebook-round-color-icon.svg'
import IconYt from '../assets/youtube-round-color-icon.svg'
import IconTt from '../assets/tiktok-round-color-icon.svg'

const twConfig = resolveConfig(tailwindConfig)

const FooterContainer = styled.div`
    position: relative;
    overflow: hidden;
    min-height: auto;
    background-color: ${twConfig.theme.colors.pattensblue};
    @media (min-width: ${twConfig.theme.screens.lg}) {
        min-height: 540px;
    }

    & .arrow-up img {
        width: 45px;
    }
    @media (min-width: ${twConfig.theme.screens.sm}) {
        & .arrow-up img { width: 52px; }
    }
    @media (min-width: ${twConfig.theme.screens.md}) {
        & .arrow-up img { width: 62px; }
    }
    @media (min-width: ${twConfig.theme.screens.lg}) {
        & .arrow-up img { width: 92px; }
    }
`
const FooterContent = styled.div`
    position: relative;
    margin-top: 230px;
    color: #7026b1;
    & .social-animated-icon {
        width: 52px;
    }
    @media (min-width: ${twConfig.theme.screens.sm}) {
        & .social-animated-icon {
            width: 70px;
        }
    }
    @media (min-width: ${twConfig.theme.screens.md}) {
        & .social-animated-icon {
            width: 80px;
        }
    }
    @media (min-width: ${twConfig.theme.screens.lg}) {
        & .social-animated-icon {
            width: 100px;
        }
    }
    @media (min-width: ${twConfig.theme.screens.xl}) {
        & .social-animated-icon {
            width: 120px;
        }
    }    
`

const Footer = () => {

    let refBackground = useRef()
    let refInView = useRef()
    const refLottieBackground = useRef()

    useEffect(() => {
        refLottieBackground.current = lottie.loadAnimation({
            container: refBackground.current, 
            animationData: backgroundData,
            loop: false,
            autoplay: false,
            delay: 500
        })
    }, [])

    return (
        <InView ref={ refInView } as="footer" onChange={ (inView) => {
            if (!refLottieBackground.current) return;
            if (inView) {
                refLottieBackground.current?.goToAndPlay(0)
            }         
        }}
        style={{ marginTop: '-180px' }}
        >
            <FooterContainer>
                <div ref={ refBackground } className="absolute bottom-0 left-0 z-10 w-full"></div>
                
                <FooterContent className="relative z-10 my-container">
                    <strong className="block text-xl text-center uppercase">Follow Us On</strong>

                    <div className="relative z-40 pt-2 text-center footer-icons">
                        {/* <FooterSocialLink href="https://www.facebook.com/TRUSTPinay/" 
                            animationData={ fbData }
                            id="facebook-icon" srOnly="Facebook"/>
                        <FooterSocialLink href="https://www.youtube.com/channel/UCLujlvyY7cMSS3JrDyKGUdg/videos" 
                            animationData={ ytData }
                            id="youtube-icon" srOnly="Youtube"/> */}
                        <div className='flex gap-4 p-4 justify-center'>
                            <OutboundLink href={ `https://www.facebook.com/TRUSTPinay/` } 
                                target="_blank" role="link" rel="noreferrer" title="FaceBook">
                                <div className='rounded-full grid content-center items-center' 
                                    style={{ width:'60px', height:'60px' }}>
                                        <IconFb/>
                                </div>
                            </OutboundLink>
                            <OutboundLink href={ `https://www.youtube.com/channel/UCLujlvyY7cMSS3JrDyKGUdg/videos` } 
                                target="_blank" role="link" rel="noreferrer" title="YouTube">
                                <div className='rounded-full grid content-center items-center bg-white' 
                                    style={{ width:'60px', height:'60px' }}>
                                        <IconYt/>
                                </div>
                            </OutboundLink>
                            <OutboundLink href={ `https://www.tiktok.com/@trust_rhchoices` } 
                                target="_blank" role="link" rel="noreferrer" title="TikTok">
                                <div className='rounded-full grid content-center items-center' 
                                    style={{ width:'60px', height:'60px' }}>
                                        <IconTt/>
                                </div>
                            </OutboundLink>
                        </div>
                    </div>
                </FooterContent>

                <div className="relative pb-2 my-container" style={{ zIndex:11 }}>
                    <button className="p-5 rounded-full cursor-pointer arrow-up bg-opacity-40" 
                        onClick={ (e) => {
                            e.preventDefault()
                            if (typeof document !== `undefined` && typeof window !== `undefined`) {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                })
                            }
                        }} 
                        aria-label="return to top button">
                        <FontAwesomeIcon icon={ faArrowUp } className="text-white" style={{ width:'26px', height:'20px' }}/>
                    </button>
                </div>

            </FooterContainer>

        </InView>
    )
}

export default Footer;