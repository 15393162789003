import React, { useState } from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import MenuItem from './MenuItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const MENU_QUERY = graphql`
    query GETMAINMENU {
        allWpMenuItem {
            nodes {
                label
                databaseId
                order
                path
                url
                target
            }
        }
    }
`

const Menu = () => {

    const [open, setOpen] = useState(false)
 
    return (
        <StaticQuery query={MENU_QUERY} render={(data) => {
            if (data.allWpMenuItem.nodes) {
                const menuItems = data.allWpMenuItem.nodes;
                return (
                    <>
                    <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
                        <a className="inline-block py-2 mr-4 text-sm font-bold leading-relaxed text-white whitespace-nowrap" href="/" aria-label="Logo">
                            <div className="lg:hidden">
                                <StaticImage src="../images/logo.png" 
                                    height={40}
                                    alt="TRUST Logo" />
                                <span className="sr-only">Logo</span>
                            </div>
                        </a>
                        <button 
                            onClick={() => setOpen(!open)}
                            className="block px-3 py-1 text-xl leading-none text-white bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none" 
                            type="button"
                            aria-label="Menu Toggle Button">
                            <FontAwesomeIcon icon={ faBars }/>
                        </button>
                    </div>
                    <div className={`lg:flex flex-grow items-center ${!open ? 'hidden':''}`}>
                        <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
                            {
                                menuItems && 
                                menuItems.map((menuItem) => (
                                    <li key={menuItem.databaseId} className="nav-item">
                                        <MenuItem menuItem={menuItem} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    </>
                )
            }
            return null;
        }}/>
    )
}

export default Menu;