module.exports = {
  purge: [
    './src/components/**/*.{js,jsx,ts}', 
    './src/templates/**/*.{js,jsx,ts}',
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      'xs': '576px',
      'sm': '640px',
      'md': '768px',
      'lg': '992px',
      'xl': '1200px',
      'xxl': '1400px',
      '2xl': '1536px'
    },
    extend: {
      transformOrigin: {
        "0": "0%"
      },
      zIndex: {
        "-1": "-1",
        "-2": "-2",
      },
      filter: ['hover'],
      colors: {
        'purpleheart': '#7026b1',
        'seance': '#8e2794',
        'pattensblue': '#e1f5ff',
        'frenchpass': '#ace2fd',
      },
    },
    container: {
      screens: {
        'xs': '576px',
        'sm': '640px',
        'md': '768px',
        'lg': '992px',
        'xl': '1200px',
        'xxl': '1400px',
        '2xl': '1536px'
      }
    }
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
  ],
}
