import React from 'react'
import { Link } from 'gatsby'

const MenuItem = ({menuItem}) => {
    return (
        <Link to={menuItem == null || menuItem.path == null ? '': menuItem.path} 
            activeClassName="text-gray-400"
            className="flex items-center px-3 py-2 text-xl tracking-wider text-white lg:py-0 xxl:py-2 lg:text-sm xl:text-xl hover:text-gray-300" 
            target={menuItem.target ? menuItem.target : ''}>
            {menuItem.label}
        </Link>
    )
}

export default MenuItem